<template>
  <div class="past-code-card">
    <div class="card-image">
      <img src="@/assets/ticket_icon2.svg" alt="ticket" />
    </div>
    <div class="card-body">
      <div class="code-description">
        <template>
          <span v-if="code.status === 'Cancelled'" class="code-status">
            {{ $t("cancelled") }}
          </span>
          <span v-else class="code-status">{{ $t("expired") }}</span>
        </template>
        <template>
          <span v-if="seasonPass">{{
            code.pass_description || code.pass_name
          }}</span>
          <span v-else>{{ code.pass_code }}</span>
        </template>
      </div>
      <div class="code-info">
        <div class="code-type">
          <span class="info-subtitle">{{ $t("type") }}</span>
          <span>{{ code.pass_name }}</span>
        </div>
        <div class="code-date">
          <template>
            <span v-if="code.status === 'Cancelled'" class="info-subtitle">
              {{ $t("cancelled") }}
            </span>
            <span v-else class="info-subtitle">{{ $t("used") }}</span>
          </template>
          <span>{{ expiresDate }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  props: ["code"],
  computed: {
    seasonPass() {
      return this.code.pass_identifier === "season";
    },
    expiresDate() {
      return moment.utc(this.code.expires).format("MMM DD, YYYY");
    },
  },
};
</script>
<style lang="scss" scoped>
.past-code-card {
  display: grid;
  grid-template-columns: max-content 1fr;
  min-height: 160px;
  padding: 24px 30px 24px 16px;
  border-radius: 4px;
  border: 1px solid #2f2e2e;
  background-color: #121212;

  @media screen and (max-width: $mobile) {
    grid-template-columns: 1fr;
  }
  .card-image {
    padding-right: 14px;
    @media screen and (max-width: $mobile) {
      display: none;
    }
  }
  .card-body {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
    gap: 16px;
    .code-description {
      display: flex;
      flex-direction: column;

      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.04px;
      text-transform: capitalize;
      .code-status {
        margin-bottom: 4px;

        color: #cf1019;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: 10px;
        text-transform: uppercase;
      }
    }
    .code-info {
      display: grid;
      grid-template-columns: 1fr max-content;
      //   grid-column: span 2;

      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.035px;
      @media screen and (max-width: $mobile) {
        margin: 16px 0;
        // grid-column: auto;
      }
      .info-subtitle {
        color: #9d9d9d;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.03px;
        text-transform: uppercase;
        margin-bottom: 4px;
      }
      .code-type {
        display: flex;
        flex-direction: column;
        margin-right: 6px;
        overflow: hidden;
        @media screen and (max-width: $mobile) {
          overflow: visible;
        }
        span {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          @media screen and (max-width: $mobile) {
            overflow: visible;
            white-space: normal;
          }
        }
      }
      .code-date {
        display: flex;
        flex-direction: column;
        text-align: end;
        @media screen and (max-width: $mobile) {
          text-align: start;
        }
      }
    }
  }
}
.past-code-card + .past-code-card {
  margin-top: 10px;
  @media screen and (max-width: $mobile) {
    // margin-top: 24px;
  }
}
</style>